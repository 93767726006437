<template>
  <v-container class="pa-4 pa-md-10">
    <v-row>
      <v-col
        :class="{
          'text-truncate': recipientsToShow.length > 200 && !showAllRecipients,
        }"
      >
        {{ $trans("to") }}: ({{
          $trans("send_customer_notification_number_info")
        }}: {{ recipients.length }}) {{ recipientsToShow }}
      </v-col>
    </v-row>
    <v-row v-if="recipientsToShow.length > 200">
      <v-col>
        <v-btn color="primary" @click="showAllRecipients = !showAllRecipients">
          <span v-if="!showAllRecipients">{{ $trans("expand") }}</span>
          <span v-else>{{ $trans("collapse") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <strong>{{ $trans("notification_type_title") }}:</strong>
        {{ $trans("notification_type_" + notificationType) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <strong>{{ $trans("notification_subject") }}:</strong> {{ subject }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <strong>{{ $trans("notification_content") }}:</strong>
        <div class="mt-4" v-html="content" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "DisplayCustomerNotificationDialog",
  mixins: [sharedActions],
  data() {
    return {
      subject: null,
      content: null,
      notificationType: null,
      customerType: null,
      recipients: [],
      showAllRecipients: false,
    };
  },
  computed: {
    recipientsToShow() {
      if (
        this.notificationType === this.$helpers.customerNotificationType.email
      ) {
        return this.recipients.map((recipient) => recipient.email).join(", ");
      }

      if (
        this.notificationType === this.$helpers.customerNotificationType.sms
      ) {
        return this.recipients.map((recipient) => recipient.phone).join(", ");
      }

      if (
        this.notificationType === this.$helpers.customerNotificationType.push
      ) {
        return this.recipients.map((recipient) => recipient.user_id).join(", ");
      }

      return "";
    },
  },
  created() {
    this.customerType = this.getDialog.data.selectedNotification.customer_type;
    this.notificationType =
      this.getDialog.data.selectedNotification.notification_type;
    this.subject = this.getDialog.data.selectedNotification.subject;
    this.content = this.getDialog.data.selectedNotification.content;
    this.recipients = this.getDialog.data.selectedNotification.recipients;
  },
};
</script>
